define('@fortawesome/pro-thin-svg-icons', ['exports'], (function (exports) { 'use strict';

  var faInfo = {
    prefix: 'fat',
    iconName: 'info',
    icon: [192, 512, [], "f129", "M80 104a24 24 0 1 0 0-48 24 24 0 1 0 0 48zm0-64a40 40 0 1 1 0 80 40 40 0 1 1 0-80zM32 184c0-4.4 3.6-8 8-8H96c4.4 0 8 3.6 8 8V464h80c4.4 0 8 3.6 8 8s-3.6 8-8 8H8c-4.4 0-8-3.6-8-8s3.6-8 8-8H88V192H40c-4.4 0-8-3.6-8-8z"]
  };
  var faFlagCheckered = {
    prefix: 'fat',
    iconName: 'flag-checkered',
    icon: [448, 512, [127937], "f11e", "M16 8c0-4.4-3.6-8-8-8S0 3.6 0 8V64 383.5 400 504c0 4.4 3.6 8 8 8s8-3.6 8-8V396l112.3-28.1c41.1-10.3 84.6-5.5 122.5 13.4c44.2 22.1 95.5 24.8 141.7 7.4l34.7-13c12.5-4.7 20.8-16.6 20.8-30V54.8c0-17.8-18.8-29.4-34.7-21.5l-19.7 9.8c-46.3 23.2-100.8 23.2-147.1 0c-35.1-17.6-75.4-22-113.5-12.5L16 60V8zm0 68.5l112-28v93.2L16 166.1V76.5zm0 106l112-24.3v87.6L16 270.1V182.4zm128-27.8l17.8-3.9c19.7-4.3 40.1-3.5 59.4 2.1l65.7 19.3c5.7 1.7 11.4 3 17.2 4.1V264c-4.3-.9-8.5-1.9-12.7-3.1l-65.7-19.3c-21.9-6.4-45-7.3-67.3-2.4L144 242.2V154.6zm176 23.8c16.5 1.4 33.1 .4 49.4-3.1L432 161.9v87.6l-66 14.1c-15.2 3.3-30.7 4.1-46 2.7V178.4zM304 280.3V382c-15.9-2.6-31.4-7.6-46.1-14.9c-35.3-17.7-75.1-24-113.9-18.5V258.6l17.8-3.9c19.7-4.3 40.1-3.5 59.4 2.1l65.7 19.3c5.7 1.7 11.4 3 17.2 4.1zm16 103.5V282.4c16.5 1.4 33.1 .4 49.4-3.1L432 265.9v79.9c0 6.7-4.1 12.6-10.4 15l-34.7 13c-21.6 8.1-44.4 11.4-66.9 10zm0-221.4V76.6c27.7 0 55.3-6.4 80.7-19.1l19.7-9.8c5.3-2.7 11.6 1.2 11.6 7.2v90.7l-66 14.1c-15.2 3.3-30.7 4.1-46 2.7zM304 75.9V160c-4.3-.9-8.5-1.9-12.7-3.1l-65.7-19.3c-21.9-6.4-45-7.3-67.3-2.4L144 138.2V44.7c32.2-6.4 65.8-1.9 95.3 12.8c20.5 10.3 42.5 16.4 64.7 18.3zM16 286.4l112-24.3v89.4c-1.2 .3-2.4 .6-3.6 .9L16 379.5V286.4z"]
  };
  var faArrowRightFromBracket = {
    prefix: 'fat',
    iconName: 'arrow-right-from-bracket',
    icon: [512, 512, ["sign-out"], "f08b", "M509.7 261.7c3.1-3.1 3.1-8.2 0-11.3l-136-136c-3.1-3.1-8.2-3.1-11.3 0s-3.1 8.2 0 11.3L484.7 248 168 248c-4.4 0-8 3.6-8 8s3.6 8 8 8l316.7 0L362.3 386.3c-3.1 3.1-3.1 8.2 0 11.3s8.2 3.1 11.3 0l136-136zM184 48c4.4 0 8-3.6 8-8s-3.6-8-8-8L88 32C39.4 32 0 71.4 0 120L0 392c0 48.6 39.4 88 88 88l96 0c4.4 0 8-3.6 8-8s-3.6-8-8-8l-96 0c-39.8 0-72-32.2-72-72l0-272c0-39.8 32.2-72 72-72l96 0z"]
  };
  var faFlag = {
    prefix: 'fat',
    iconName: 'flag',
    icon: [448, 512, [127988, 61725], "f024", "M16 8c0-4.4-3.6-8-8-8S0 3.6 0 8V64 383.5 400 504c0 4.4 3.6 8 8 8s8-3.6 8-8V396l112.3-28.1c41.1-10.3 84.6-5.5 122.5 13.4c44.2 22.1 95.5 24.8 141.7 7.4l34.7-13c12.5-4.7 20.8-16.6 20.8-30V54.8c0-17.8-18.8-29.4-34.7-21.5l-19.7 9.8c-46.3 23.2-100.8 23.2-147.1 0c-35.1-17.6-75.4-22-113.5-12.5L16 60V8zm0 68.5L136.8 46.3c34.4-8.6 70.7-4.6 102.4 11.2c50.8 25.4 110.6 25.4 161.5 0l19.7-9.8c5.3-2.7 11.6 1.2 11.6 7.2v291c0 6.7-4.1 12.6-10.4 15l-34.7 13c-42.1 15.8-88.8 13.3-129-6.8c-41.3-20.7-88.7-25.9-133.5-14.7L16 379.5V76.5z"]
  };
  var faChevronUp = {
    prefix: 'fat',
    iconName: 'chevron-up',
    icon: [512, 512, [], "f077", "M250.3 114.3c3.1-3.1 8.2-3.1 11.3 0l216 216c3.1 3.1 3.1 8.2 0 11.3s-8.2 3.1-11.3 0L256 131.3 45.7 341.7c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3l216-216z"]
  };
  var faStopwatch = {
    prefix: 'fat',
    iconName: 'stopwatch',
    icon: [448, 512, [9201], "f2f2", "M144 8c0-4.4 3.6-8 8-8H296c4.4 0 8 3.6 8 8s-3.6 8-8 8H232V96.2c55.4 2.1 105.3 25.9 141.4 63.1l36.9-36.9c3.1-3.1 8.2-3.1 11.3 0s3.1 8.2 0 11.3l-37.6 37.6C414 207.2 432 253.5 432 304c0 114.9-93.1 208-208 208S16 418.9 16 304c0-112.2 88.8-203.6 200-207.8V16H152c-4.4 0-8-3.6-8-8zm80 488a192 192 0 1 0 0-384 192 192 0 1 0 0 384zm8-312V320c0 4.4-3.6 8-8 8s-8-3.6-8-8V184c0-4.4 3.6-8 8-8s8 3.6 8 8z"]
  };
  var faPenCircle = {
    prefix: 'fat',
    iconName: 'pen-circle',
    icon: [512, 512, [], "e20e", "M496 256A240 240 0 1 0 16 256a240 240 0 1 0 480 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM132.7 379.2c-4-4-5.6-9.7-4.2-15.2l15-60.1c1.4-5.6 4.3-10.8 8.4-14.9L307.4 133.5c15.6-15.6 40.9-15.6 56.6 0l14.4 14.4c15.6 15.6 15.6 40.9 0 56.6L222.9 359.9c-4.1 4.1-9.2 7-14.9 8.4l-60.1 15c-5.5 1.4-11.2-.2-15.2-4.2zM367 193.1c9.4-9.4 9.4-24.6 0-33.9l-14.4-14.4c-9.4-9.4-24.6-9.4-33.9 0l-25.3 25.3 48.3 48.3L367 193.1zM211.6 348.6L330.4 229.7l-48.3-48.3L163.2 300.3c-2.1 2.1-3.5 4.6-4.2 7.4l-10.6 42.5L144 367.9l17.6-4.4 42.5-10.6c2.8-.7 5.4-2.2 7.4-4.2z"]
  };
  var faBadgePercent = {
    prefix: 'fat',
    iconName: 'badge-percent',
    icon: [512, 512, [], "f646", "M180.8 61.2C195.1 34.3 223.4 16 256 16s60.9 18.3 75.2 45.2c1.8 3.4 5.7 5 9.4 3.9c29.1-8.9 62.1-1.9 85.1 21.1s30.1 56 21.1 85.1c-1.1 3.7 .5 7.6 3.9 9.4C477.7 195.1 496 223.4 496 256s-18.3 60.9-45.2 75.2c-3.4 1.8-5 5.8-3.9 9.4c8.9 29.1 1.9 62.1-21.1 85.1s-56 30.1-85.1 21.1c-3.7-1.1-7.6 .5-9.4 3.9C316.9 477.7 288.6 496 256 496s-60.9-18.3-75.2-45.2c-1.8-3.4-5.7-5-9.4-3.9c-29.1 8.9-62.1 1.9-85.1-21.1s-30.1-56-21.1-85.1c1.1-3.7-.5-7.6-3.9-9.4C34.3 316.9 16 288.6 16 256s18.3-60.9 45.2-75.2c3.4-1.8 5-5.7 3.9-9.4c-8.9-29.1-1.9-62.1 21.1-85.1s56-30.1 85.1-21.1c3.7 1.1 7.6-.5 9.4-3.9zM256 0c-36.4 0-68.3 19.3-86.1 48.2c-33-7.9-69.2 1.1-94.9 26.8s-34.7 61.9-26.8 94.9C19.3 187.7 0 219.6 0 256s19.3 68.3 48.2 86.1c-7.9 33 1.1 69.2 26.8 94.9s61.9 34.7 94.9 26.8C187.7 492.7 219.6 512 256 512s68.3-19.3 86.1-48.2c33 7.9 69.2-1.1 94.9-26.8s34.7-61.9 26.8-94.9C492.7 324.3 512 292.4 512 256s-19.3-68.3-48.2-86.1c7.9-33-1.1-69.2-26.8-94.9s-62-34.7-94.9-26.8C324.3 19.3 292.4 0 256 0zM176 192a16 16 0 1 1 32 0 16 16 0 1 1 -32 0zm48 0a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm96 112a16 16 0 1 1 0 32 16 16 0 1 1 0-32zm0 48a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm21.7-170.3c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0l-160 160c-3.1 3.1-3.1 8.2 0 11.3s8.2 3.1 11.3 0l160-160z"]
  };
  var faUser = {
    prefix: 'fat',
    iconName: 'user',
    icon: [448, 512, [128100, 62144], "f007", "M336 128a112 112 0 1 0 -224 0 112 112 0 1 0 224 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM16 482.3c0 7.6 6.1 13.7 13.7 13.7H418.3c7.6 0 13.7-6.1 13.7-13.7C432 392.7 359.3 320 269.7 320H178.3C88.7 320 16 392.7 16 482.3zm-16 0C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z"]
  };
  var faGlobe = {
    prefix: 'fat',
    iconName: 'globe',
    icon: [512, 512, [127760], "f0ac", "M256 496c26 0 53.9-21.5 76.2-66.1c11-21.9 19.9-48.3 26.2-77.9H153.6c6.2 29.5 15.2 55.9 26.2 77.9C202.1 474.5 230 496 256 496zM150.6 336H361.4c4.3-25 6.6-51.9 6.6-80s-2.3-55-6.6-80H150.6c-4.3 25-6.6 51.9-6.6 80s2.3 55 6.6 80zm3-176H358.4c-6.2-29.6-15.2-55.9-26.2-77.9C309.9 37.5 282 16 256 16s-53.9 21.5-76.2 66.1c-11 21.9-19.9 48.3-26.2 77.9zm224 16c4.1 25.2 6.4 52.1 6.4 80s-2.2 54.8-6.4 80H482.3c8.8-25 13.7-51.9 13.7-80s-4.8-55-13.7-80H377.6zM476 160C445.6 90.5 383.2 38.1 307.5 21.5c30.5 26.8 54.7 76.6 67.2 138.5H476zm-338.7 0c12.5-61.8 36.8-111.7 67.2-138.5C128.8 38.1 66.4 90.5 36 160H137.3zM29.7 176C20.8 201 16 227.9 16 256s4.8 55 13.7 80H134.4c-4.1-25.2-6.4-52.1-6.4-80s2.2-54.8 6.4-80H29.7zM307.5 490.5C383.2 473.9 445.6 421.5 476 352H374.7c-12.5 61.8-36.8 111.7-67.2 138.5zm-102.9 0c-30.5-26.8-54.7-76.6-67.2-138.5H36c30.4 69.5 92.8 121.9 168.6 138.5zM256 512A256 256 0 1 1 256 0a256 256 0 1 1 0 512z"]
  };
  var faBan = {
    prefix: 'fat',
    iconName: 'ban',
    icon: [512, 512, [128683, "cancel"], "f05e", "M420 431.3L80.7 92C40.6 134.9 16 192.6 16 256c0 132.5 107.5 240 240 240c63.4 0 121.1-24.6 164-64.7zM431.3 420C471.4 377.1 496 319.4 496 256C496 123.5 388.5 16 256 16C192.6 16 134.9 40.6 92 80.7L431.3 420zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"]
  };
  var faCircle = {
    prefix: 'fat',
    iconName: 'circle',
    icon: [512, 512, [128308, 128309, 128992, 128993, 128994, 128995, 128996, 9679, 9898, 9899, 11044, 61708, 61915], "f111", "M496 256A240 240 0 1 0 16 256a240 240 0 1 0 480 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"]
  };
  var faCircleQuestion = {
    prefix: 'fat',
    iconName: 'circle-question',
    icon: [512, 512, [62108, "question-circle"], "f059", "M496 256A240 240 0 1 0 16 256a240 240 0 1 0 480 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm168-56c0-30.9 25.1-56 56-56h56.2c35.2 0 63.8 28.6 63.8 63.8c0 21.4-10.8 41.5-28.7 53.3L264 294.9V320c0 4.4-3.6 8-8 8s-8-3.6-8-8V290.5c0-2.7 1.4-5.2 3.6-6.7l54.9-36.2c13.4-8.8 21.5-23.8 21.5-39.9c0-26.4-21.4-47.8-47.8-47.8H224c-22.1 0-40 17.9-40 40l0 6.5c0 4.4-3.6 8-8 8s-8-3.6-8-8l0-6.5zm72 168a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z"]
  };
  var faQuestionCircle = faCircleQuestion;
  var faSpinnerThird = {
    prefix: 'fat',
    iconName: 'spinner-third',
    icon: [512, 512, [], "f3f4", "M470.8 380c3.8 2.2 8.7 .9 10.8-3C501 341 512 299.8 512 256C512 117.3 401.7 4.4 264 .1c-4.4-.1-8 3.5-8 7.9s3.6 8 8 8.1c128.8 4.2 232 110 232 239.9c0 40.9-10.2 79.3-28.2 113c-2.1 3.9-.8 8.8 3 11z"]
  };
  var faPhone = {
    prefix: 'fat',
    iconName: 'phone',
    icon: [512, 512, [128222, 128379], "f095", "M317.1 378.1c-4.7 5.7-12.6 7.5-19.2 4.3c-73.8-34.8-133.5-94.5-168.3-168.3c-3.1-6.6-1.3-14.6 4.3-19.2l49.3-40.4c8.2-6.7 11-18 7-27.8l-40-96c-4.6-11.1-16.8-17.1-28.5-13.9l-88 24C23.2 43.7 16 53.2 16 64c0 238.6 193.4 432 432 432c10.8 0 20.3-7.2 23.2-17.7l24-88c3.2-11.6-2.8-23.8-13.9-28.5l-96-40c-9.8-4.1-21.1-1.3-27.8 7l-40.4 49.3zM304.7 368L345 318.7c11.2-13.7 30-18.4 46.3-11.6l96 40c18.6 7.7 28.5 28 23.2 47.4l-24 88C481.8 499.9 466 512 448 512C200.6 512 0 311.4 0 64C0 46 12.1 30.2 29.5 25.4l88-24c19.4-5.3 39.7 4.6 47.4 23.2l40 96c6.8 16.3 2.1 35.2-11.6 46.3L144 207.3c33.3 70.4 90.3 127.4 160.7 160.7z"]
  };
  var faTrash = {
    prefix: 'fat',
    iconName: 'trash',
    icon: [448, 512, [], "f1f8", "M177.7 16h92.5c8.3 0 16 4.3 20.4 11.3l23 36.7H134.4l23-36.7c4.4-7 12.1-11.3 20.4-11.3zm-33.9 2.8L115.6 64H8c-4.4 0-8 3.6-8 8s3.6 8 8 8H440c4.4 0 8-3.6 8-8s-3.6-8-8-8H332.4L304.2 18.8C296.9 7.1 284.1 0 270.3 0H177.7c-13.8 0-26.6 7.1-33.9 18.8zM48 119.3c-.4-4.4-4.2-7.7-8.6-7.3s-7.7 4.2-7.3 8.6l28.3 340c2.4 29 26.7 51.4 55.8 51.4H331.8c29.1 0 53.4-22.3 55.8-51.4l28.3-340c.4-4.4-2.9-8.3-7.3-8.6s-8.3 2.9-8.6 7.3l-28.3 340C370 480.1 352.6 496 331.8 496H116.2c-20.8 0-38.1-15.9-39.9-36.7L48 119.3z"]
  };
  var faCircleInfo = {
    prefix: 'fat',
    iconName: 'circle-info',
    icon: [512, 512, ["info-circle"], "f05a", "M256 16a240 240 0 1 1 0 480 240 240 0 1 1 0-480zm0 496A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM208 352c-4.4 0-8 3.6-8 8s3.6 8 8 8h96c4.4 0 8-3.6 8-8s-3.6-8-8-8H264V216c0-4.4-3.6-8-8-8H224c-4.4 0-8 3.6-8 8s3.6 8 8 8h24V352H208zm48-176a16 16 0 1 0 0-32 16 16 0 1 0 0 32z"]
  };
  var faInfoCircle = faCircleInfo;
  var faGear = {
    prefix: 'fat',
    iconName: 'gear',
    icon: [512, 512, [9881, "cog"], "f013", "M211.6 28.5c1.6-7.3 8.1-12.5 15.6-12.5h57.6c7.5 0 14 5.2 15.6 12.5l12.9 57.1c.6 2.6 2.5 4.8 5 5.7c17.9 6.8 34.5 16.4 49.1 28.4c2.1 1.7 4.9 2.2 7.4 1.4l56-17.4c7.1-2.2 14.9 .8 18.6 7.3L478.3 161c3.7 6.5 2.5 14.7-3 19.8l-43.1 39.8c-2 1.8-2.9 4.5-2.5 7.2c1.5 9.2 2.3 18.7 2.3 28.4s-.8 19.1-2.3 28.4c-.4 2.6 .5 5.3 2.5 7.2l43.1 39.8c5.5 5.1 6.7 13.3 3 19.8L449.4 401c-3.7 6.5-11.5 9.5-18.6 7.3l-56-17.4c-2.6-.8-5.4-.3-7.4 1.4c-14.6 11.9-31.2 21.6-49.1 28.4c-2.5 1-4.4 3.1-5 5.7l-12.9 57.1c-1.6 7.3-8.1 12.5-15.6 12.5H227.2c-7.5 0-14-5.2-15.6-12.5l-12.9-57.1c-.6-2.6-2.5-4.8-5-5.7c-17.9-6.8-34.5-16.4-49.1-28.4c-2.1-1.7-4.9-2.2-7.4-1.4l-56 17.4c-7.1 2.2-14.9-.8-18.6-7.3L33.7 351c-3.7-6.5-2.5-14.7 3-19.8l43.1-39.8c2-1.8 2.9-4.5 2.5-7.2C80.8 275.1 80 265.7 80 256s.8-19.1 2.3-28.4c.4-2.6-.5-5.3-2.5-7.2L36.7 180.7c-5.5-5.1-6.7-13.3-3-19.8L62.6 111c3.7-6.5 11.5-9.5 18.6-7.3l2.4-7.6-2.4 7.6 56 17.4c2.6 .8 5.4 .3 7.4-1.4c14.6-12 31.2-21.6 49.1-28.4c2.5-1 4.4-3.1 5-5.7l12.9-57.1zM227.2 0c-15 0-27.9 10.4-31.2 25L184 78c-16.7 6.8-32.2 15.8-46.1 26.7L85.9 88.5C71.6 84 56.2 90.1 48.7 103L19.9 153c-7.5 13-5 29.4 6 39.5l39.9 36.9C64.6 238.1 64 247 64 256s.6 17.9 1.8 26.6L25.9 319.5l5.4 5.9-5.4-5.9c-11 10.1-13.5 26.6-6 39.5L48.7 409c7.5 13 22.9 19 37.2 14.6l51.9-16.2c14 10.9 29.5 19.9 46.1 26.7l12 53c3.3 14.6 16.3 25 31.2 25h57.6c15 0 27.9-10.4 31.2-25l12-53c16.7-6.7 32.2-15.8 46.1-26.7l51.9 16.2c14.3 4.4 29.7-1.6 37.2-14.6L492.1 359c7.5-13 5-29.4-6-39.5l-39.9-36.9c1.2-8.7 1.8-17.6 1.8-26.6s-.6-17.9-1.8-26.6l39.9-36.9c11-10.1 13.5-26.6 6-39.5L463.3 103c-7.5-13-22.9-19-37.2-14.6l-51.9 16.2C360.2 93.7 344.7 84.7 328 78L316 25c-3.3-14.6-16.3-25-31.2-25H227.2zM192 256a64 64 0 1 1 128 0 64 64 0 1 1 -128 0zm144 0a80 80 0 1 0 -160 0 80 80 0 1 0 160 0z"]
  };
  var faClock = {
    prefix: 'fat',
    iconName: 'clock',
    icon: [512, 512, [128339, "clock-four"], "f017", "M496 256A240 240 0 1 1 16 256a240 240 0 1 1 480 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM248 104V256c0 2.7 1.3 5.2 3.6 6.7l96 64c3.7 2.5 8.6 1.5 11.1-2.2s1.5-8.6-2.2-11.1L264 251.7V104c0-4.4-3.6-8-8-8s-8 3.6-8 8z"]
  };
  var faLoader = {
    prefix: 'fat',
    iconName: 'loader',
    icon: [512, 512, [], "e1d4", "M264 8V96c0 4.4-3.6 8-8 8s-8-3.6-8-8V8c0-4.4 3.6-8 8-8s8 3.6 8 8zm0 408v88c0 4.4-3.6 8-8 8s-8-3.6-8-8V416c0-4.4 3.6-8 8-8s8 3.6 8 8zM-8 256c0-4.4 3.6-8 8-8H96c4.4 0 8 3.6 8 8s-3.6 8-8 8H0c-4.4 0-8-3.6-8-8zm424-8h88c4.4 0 8 3.6 8 8s-3.6 8-8 8H416c-4.4 0-8-3.6-8-8s3.6-8 8-8zM435.8 77.8c3.1 3.1 3.1 8.2 0 11.3l-62.2 62.2c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3l62.2-62.2c3.1-3.1 8.2-3.1 11.3 0zM147.3 377.6L85.1 439.8c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3L136 366.3c3.1-3.1 8.2-3.1 11.3 0s3.1 8.2 0 11.3zM68.2 72.2c3.1-3.1 8.2-3.1 11.3 0L147.3 140c3.1 3.1 3.1 8.2 0 11.3s-8.2 3.1-11.3 0L68.2 83.5c-3.1-3.1-3.1-8.2 0-11.3zM373.6 366.3l62.2 62.2c3.1 3.1 3.1 8.2 0 11.3s-8.2 3.1-11.3 0l-62.2-62.2c-3.1-3.1-3.1-8.2 0-11.3s8.2-3.1 11.3 0z"]
  };
  var faHouse = {
    prefix: 'fat',
    iconName: 'house',
    icon: [576, 512, [127968, 63498, 63500, "home", "home-alt", "home-lg-alt"], "f015", "M293.3 2c-3-2.7-7.6-2.7-10.6 0L2.7 250c-3.3 2.9-3.6 8-.7 11.3s8 3.6 11.3 .7L64 217.1V448c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V217.1L562.7 262c3.3 2.9 8.4 2.6 11.3-.7s2.6-8.4-.7-11.3L293.3 2zM80 448V202.9L288 18.7 496 202.9V448c0 26.5-21.5 48-48 48H368V328c0-13.3-10.7-24-24-24H232c-13.3 0-24 10.7-24 24V496H128c-26.5 0-48-21.5-48-48zm144 48V328c0-4.4 3.6-8 8-8H344c4.4 0 8 3.6 8 8V496H224z"]
  };
  var faRotateRight = {
    prefix: 'fat',
    iconName: 'rotate-right',
    icon: [512, 512, ["redo-alt", "rotate-forward"], "f2f9", "M92.1 384.1c70.7 90.5 201.4 106.6 292 35.8c22.9-17.9 41.1-39.7 54.2-63.7c2.1-3.9 7-5.3 10.9-3.2s5.3 7 3.2 10.9c-14.2 25.8-33.7 49.3-58.4 68.6c-97.5 76.2-238.3 58.9-314.4-38.6S20.6 155.7 118.1 79.5c89.8-70.2 216.4-61 295.3 17.1l-11.3 11.3c-73.3-72.5-190.8-81-274.2-15.8c-90.5 70.7-106.6 201.4-35.8 292zM480 192c4.4 0 8-3.6 8-8V64c0-3.2-1.9-6.2-4.9-7.4s-6.4-.6-8.7 1.7l-120 120c-2.3 2.3-3 5.7-1.7 8.7s4.2 4.9 7.4 4.9H480zM360 208c-9.7 0-18.5-5.8-22.2-14.8s-1.7-19.3 5.2-26.2L463 47c6.9-6.9 17.2-8.9 26.2-5.2s14.8 12.5 14.8 22.2V184c0 13.3-10.7 24-24 24H360z"]
  };
  var faCircleDollar = {
    prefix: 'fat',
    iconName: 'circle-dollar',
    icon: [512, 512, ["dollar-circle", "usd-circle"], "f2e8", "M496 256A240 240 0 1 0 16 256a240 240 0 1 0 480 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM255.5 96c4.4 0 8 3.6 8 8v35.2c6.5 .1 13.2 .6 20 1.6c9.6 1.4 19 4.1 28.4 6.8l0 0 0 0c2.8 .8 5.5 1.6 8.3 2.3c4.3 1.2 6.8 5.6 5.6 9.9s-5.6 6.8-9.8 5.6c-2.8-.8-5.5-1.6-8.3-2.4l0 0c-8.7-2.5-17.6-5.1-26.5-6.4c-22.9-3.4-44.4-.5-60.4 6.4c-16.2 7-25.8 17.5-27.9 29.1c-2.7 14.8 3.4 26.4 15.8 34.4c13.2 8.4 32.1 13.8 52.9 19.7l4.6 1.3c18.8 5.3 38.3 10.8 54.5 21.9c18.1 12.4 26.2 31.7 22.2 53.3c-3.7 20.1-17.5 33.9-36.2 41.4c-12.4 5-27.2 7.4-43.1 7.3l0 36.7c0 4.4-3.6 8-8 8s-8-3.6-8-8l0-37.6c-2.7-.3-5.4-.6-8.1-1l0 0c-8.2-1.3-22.9-5.5-35.1-9.1c-7.6-2.3-15.3-4.7-22.9-7.2c-4.2-1.4-6.5-5.9-5.1-10.1s5.9-6.5 10.1-5.1c7.5 2.4 15 4.8 22.5 7.1c12.4 3.8 26 7.6 32.8 8.6c23.3 3.4 43.8 1.7 58.9-4.4c14.9-6 24-15.9 26.5-29.4c2.8-15.5-2.6-28.3-15.5-37.2c-14.5-9.9-32.1-14.8-49-19.5l0 0 0 0c-2.3-.6-4.6-1.3-6.8-1.9c-19.6-5.6-40.5-11.5-55.7-21.2c-17.9-11.4-26.8-29.6-22.9-50.7c3.4-18.7 18.3-32.7 37.3-40.9c9.8-4.2 21.1-7.1 33.1-8.4V104c0-4.4 3.6-8 8-8z"]
  };
  var faIndustry = {
    prefix: 'fat',
    iconName: 'industry',
    icon: [576, 512, [], "f275", "M72 48C58.7 48 48 58.7 48 72V304v48 72c0 22.1 17.9 40 40 40H488c22.1 0 40-17.9 40-40V320 304 152.9c0-12.2-13.2-19.9-23.8-14L363.9 217.5c-2.5 1.4-5.5 1.4-8-.1s-4-4.1-4-6.9V153.8c0-12.3-13.4-20-24.1-13.8L188 221.6c-2.5 1.4-5.5 1.5-8 0s-4-4.1-4-6.9V72c0-13.3-10.7-24-24-24H72zM32 72c0-22.1 17.9-40 40-40h80c22.1 0 40 17.9 40 40V200.7l127.9-74.6c21.3-12.4 48.1 2.9 48.1 27.6v43.1L496.4 125c21.3-11.9 47.6 3.5 47.6 27.9V304v16V424c0 30.9-25.1 56-56 56H88c-30.9 0-56-25.1-56-56V352 304 72z"]
  };
  var faBug = {
    prefix: 'fat',
    iconName: 'bug',
    icon: [512, 512, [], "f188", "M336 96c0-44.2-35.8-80-80-80s-80 35.8-80 80v3.6c0 6.9 5.6 12.4 12.4 12.4H323.6c6.9 0 12.4-5.6 12.4-12.4V96zM160 96c0-53 43-96 96-96s96 43 96 96v3.6c0 15.7-12.7 28.4-28.4 28.4H188.4c-15.7 0-28.4-12.7-28.4-28.4V96zM34.3 98.3c3.1-3.1 8.2-3.1 11.3 0L132.3 185c18.2-15.6 41.8-25 67.7-25H312c25.8 0 49.5 9.4 67.7 25l86.7-86.7c3.1-3.1 8.2-3.1 11.3 0s3.1 8.2 0 11.3L391 196.3c15.6 18.2 25 41.8 25 67.7v16h88c4.4 0 8 3.6 8 8s-3.6 8-8 8H416v24c0 33.2-10.1 63.9-27.3 89.5c.4 .3 .7 .5 1 .9l88 88c3.1 3.1 3.1 8.2 0 11.3s-8.2 3.1-11.3 0L379 422.3C349.7 457.6 305.4 480 256 480s-93.7-22.4-123-57.7L45.7 509.7c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3l88-88c.3-.3 .7-.6 1-.9C106.1 383.9 96 353.2 96 320V296H8c-4.4 0-8-3.6-8-8s3.6-8 8-8H96V264c0-25.8 9.4-49.5 25-67.7L34.3 109.7c-3.1-3.1-3.1-8.2 0-11.3zM264 232V463.8c75.8-4.2 136-66.9 136-143.8V288 264c0-48.6-39.4-88-88-88H200c-48.6 0-88 39.4-88 88v24 32c0 76.8 60.2 139.6 136 143.8V232c0-4.4 3.6-8 8-8s8 3.6 8 8z"]
  };
  var faLocationDot = {
    prefix: 'fat',
    iconName: 'location-dot',
    icon: [384, 512, ["map-marker-alt"], "f3c5", "M368 192c0-97.2-78.8-176-176-176S16 94.8 16 192c0 18.7 6.4 42.5 17.8 69.6c11.3 26.9 27.1 55.8 44.7 84.3c35.2 57 76.8 111.4 102.3 143.2c5.9 7.3 16.6 7.3 22.4 0c25.5-31.8 67.1-86.2 102.3-143.2c17.6-28.5 33.4-57.4 44.7-84.3C361.6 234.5 368 210.7 368 192zm16 0c0 87.4-117 243-168.3 307.2c-12.3 15.3-35.1 15.3-47.4 0C117 435 0 279.4 0 192C0 86 86 0 192 0S384 86 384 192zM192 112a80 80 0 1 1 0 160 80 80 0 1 1 0-160zm64 80a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z"]
  };
  var faNotebook = {
    prefix: 'fat',
    iconName: 'notebook',
    icon: [512, 512, [], "e201", "M208 16H416c26.5 0 48 21.5 48 48V448c0 26.5-21.5 48-48 48H208V16zm-16 0V496H128c-26.5 0-48-21.5-48-48V392h40c4.4 0 8-3.6 8-8s-3.6-8-8-8H80V264h40c4.4 0 8-3.6 8-8s-3.6-8-8-8H80V136h40c4.4 0 8-3.6 8-8s-3.6-8-8-8H80V64c0-26.5 21.5-48 48-48h64zM64 392v56c0 35.3 28.7 64 64 64H416c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H128C92.7 0 64 28.7 64 64v56H8c-4.4 0-8 3.6-8 8s3.6 8 8 8H64V248H8c-4.4 0-8 3.6-8 8s3.6 8 8 8H64V376H8c-4.4 0-8 3.6-8 8s3.6 8 8 8H64z"]
  };
  var faChevronDown = {
    prefix: 'fat',
    iconName: 'chevron-down',
    icon: [512, 512, [], "f078", "M250.3 397.7c3.1 3.1 8.2 3.1 11.3 0l216-216c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0L256 380.7 45.7 170.3c-3.1-3.1-8.2-3.1-11.3 0s-3.1 8.2 0 11.3l216 216z"]
  };
  var faCopy = {
    prefix: 'fat',
    iconName: 'copy',
    icon: [448, 512, [], "f0c5", "M384 368H224c-26.5 0-48-21.5-48-48V64c0-26.5 21.5-48 48-48H332.1c8.5 0 16.6 3.4 22.6 9.4l67.9 67.9c6 6 9.4 14.1 9.4 22.6V320c0 26.5-21.5 48-48 48zM224 384H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H224c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H224c35.3 0 64-28.7 64-64V416H272v32c0 26.5-21.5 48-48 48H64c-26.5 0-48-21.5-48-48V192c0-26.5 21.5-48 48-48h64V128H64z"]
  };
  var faPlus = {
    prefix: 'fat',
    iconName: 'plus',
    icon: [448, 512, [10133, 61543, "add"], "2b", "M232 72c0-4.4-3.6-8-8-8s-8 3.6-8 8V248H40c-4.4 0-8 3.6-8 8s3.6 8 8 8H216V440c0 4.4 3.6 8 8 8s8-3.6 8-8V264H408c4.4 0 8-3.6 8-8s-3.6-8-8-8H232V72z"]
  };
  var faXmark = {
    prefix: 'fat',
    iconName: 'xmark',
    icon: [384, 512, [128473, 10005, 10006, 10060, 215, "close", "multiply", "remove", "times"], "f00d", "M338.1 413.4c3.1 3.1 8.2 3.1 11.3 0s3.1-8.2 0-11.3L203.3 256 349.4 109.9c3.1-3.1 3.1-8.2 0-11.3s-8.2-3.1-11.3 0L192 244.7 45.9 98.6c-3.1-3.1-8.2-3.1-11.3 0s-3.1 8.2 0 11.3L180.7 256 34.6 402.1c-3.1 3.1-3.1 8.2 0 11.3s8.2 3.1 11.3 0L192 267.3 338.1 413.4z"]
  };
  var faChevronRight = {
    prefix: 'fat',
    iconName: 'chevron-right',
    icon: [320, 512, [9002], "f054", "M301.7 250.3c3.1 3.1 3.1 8.2 0 11.3l-216 216c-3.1 3.1-8.2 3.1-11.3 0s-3.1-8.2 0-11.3L284.7 256 74.3 45.7c-3.1-3.1-3.1-8.2 0-11.3s8.2-3.1 11.3 0l216 216z"]
  };
  var faTrafficLight = {
    prefix: 'fat',
    iconName: 'traffic-light',
    icon: [320, 512, [128678], "f637", "M64 16C37.5 16 16 37.5 16 64V352c0 79.5 64.5 144 144 144s144-64.5 144-144V64c0-26.5-21.5-48-48-48H64zM0 64C0 28.7 28.7 0 64 0H256c35.3 0 64 28.7 64 64V352c0 88.4-71.6 160-160 160S0 440.4 0 352V64zM120 392a40 40 0 1 0 80 0 40 40 0 1 0 -80 0zm96 0a56 56 0 1 1 -112 0 56 56 0 1 1 112 0zM160 216a40 40 0 1 0 0 80 40 40 0 1 0 0-80zm0 96a56 56 0 1 1 0-112 56 56 0 1 1 0 112zM120 120a40 40 0 1 0 80 0 40 40 0 1 0 -80 0zm96 0a56 56 0 1 1 -112 0 56 56 0 1 1 112 0z"]
  };
  var faHammer = {
    prefix: 'fat',
    iconName: 'hammer',
    icon: [576, 512, [128296], "f6e3", "M424.8 226.2l17.5 17.5c6.2 6.2 6.2 16.4 0 22.6l-8 8c-3.1 3.1-3.1 8.2 0 11.3l24 24c3.1 3.1 8.2 3.1 11.3 0l88-88c3.1-3.1 3.1-8.2 0-11.3l-24-24c-3.1-3.1-8.2-3.1-11.3 0l-8 8c-6.2 6.2-16.4 6.2-22.6 0l-15.9-15.9c-3.9-3.9-5.5-9.4-4.3-14.7c5.5-25-2-52.2-21.9-71L405 50.8C381.2 28.4 349.8 16 317.2 16H299.2c-17.9 0-35.6 3.8-52 11L200 48l79.7 43.5c5.1 2.8 8.3 8.2 8.3 14v12.1c0 16.9 6.7 33.1 18.6 45l38.1 38.1c18 18 42.7 24.9 66 21c5.1-.9 10.3 .8 14 4.5zM460.5 81.1c24.2 22.8 33.2 55.7 26.6 86l4.6 4.6L503 183l8-8c9.4-9.4 24.6-9.4 33.9 0l24 24c9.4 9.4 9.4 24.6 0 33.9l-88 88c-9.4 9.4-24.6 9.4-33.9 0l-24-24c-9.4-9.4-9.4-24.6 0-33.9l8-8-11.3-11.3-6.2-6.2c-28.2 4.8-58.2-3.6-80-25.4l-38.1-38.1C280.4 159 272 138.8 272 117.6V105.5L192.3 62c-5.3-2.9-8.6-8.6-8.3-14.7s3.9-11.5 9.5-14l47.2-21C259.1 4.2 279 0 299.2 0h18.1c36.7 0 72 14 98.7 39.1l44.6 42zM27.4 377.1L260.9 182.6c3.1 4.3 6.5 8.4 10.2 12.3L37.7 389.4 27.4 377.1zm0 0l10.2 12.3C23.9 400.9 16 417.8 16 435.7C16 469 43 496 76.3 496c17.9 0 34.9-7.9 46.3-21.7L317.3 240.7c4.1 3.5 8.3 6.8 12.7 9.7L134.9 484.6c-14.5 17.4-36 27.4-58.6 27.4C34.1 512 0 477.8 0 435.7c0-22.6 10.1-44.1 27.4-58.6z"]
  };
  var faBuilding = {
    prefix: 'fat',
    iconName: 'building',
    icon: [384, 512, [127970, 61687], "f1ad", "M48 16C30.3 16 16 30.3 16 48V464c0 17.7 14.3 32 32 32h96V416c0-26.5 21.5-48 48-48s48 21.5 48 48v80h96c17.7 0 32-14.3 32-32V48c0-17.7-14.3-32-32-32H48zM160 496h64V416c0-17.7-14.3-32-32-32s-32 14.3-32 32v80zm-16 16H48c-26.5 0-48-21.5-48-48V48C0 21.5 21.5 0 48 0H336c26.5 0 48 21.5 48 48V464c0 26.5-21.5 48-48 48H240 224 160 144zM104 232h32c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H104c-13.3 0-24-10.7-24-24V256c0-13.3 10.7-24 24-24zm-8 24v32c0 4.4 3.6 8 8 8h32c4.4 0 8-3.6 8-8V256c0-4.4-3.6-8-8-8H104c-4.4 0-8 3.6-8 8zm128 0c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H248c-13.3 0-24-10.7-24-24V256zm24-8c-4.4 0-8 3.6-8 8v32c0 4.4 3.6 8 8 8h32c4.4 0 8-3.6 8-8V256c0-4.4-3.6-8-8-8H248zM104 104h32c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H104c-13.3 0-24-10.7-24-24V128c0-13.3 10.7-24 24-24zm-8 24v32c0 4.4 3.6 8 8 8h32c4.4 0 8-3.6 8-8V128c0-4.4-3.6-8-8-8H104c-4.4 0-8 3.6-8 8zm128 0c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H248c-13.3 0-24-10.7-24-24V128zm24-8c-4.4 0-8 3.6-8 8v32c0 4.4 3.6 8 8 8h32c4.4 0 8-3.6 8-8V128c0-4.4-3.6-8-8-8H248z"]
  };
  var faDoNotEnter = {
    prefix: 'fat',
    iconName: 'do-not-enter',
    icon: [512, 512, [], "f5ec", "M496 256A240 240 0 1 0 16 256a240 240 0 1 0 480 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm96-16v32c0 8.8 7.2 16 16 16H400c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16zm-16 0c0-17.7 14.3-32 32-32H400c17.7 0 32 14.3 32 32v32c0 17.7-14.3 32-32 32H112c-17.7 0-32-14.3-32-32V240z"]
  };
  var faCheck = {
    prefix: 'fat',
    iconName: 'check',
    icon: [448, 512, [10003, 10004], "f00c", "M437.7 106.3c3.1 3.1 3.1 8.2 0 11.3l-272 272c-3.1 3.1-8.2 3.1-11.3 0l-144-144c-3.1-3.1-3.1-8.2 0-11.3s8.2-3.1 11.3 0L160 372.7 426.3 106.3c3.1-3.1 8.2-3.1 11.3 0z"]
  };
  var faBuildings = {
    prefix: 'fat',
    iconName: 'buildings',
    icon: [512, 512, [], "e0cc", "M256 16c-26.5 0-48 21.5-48 48V448c0 26.5 21.5 48 48 48H448c26.5 0 48-21.5 48-48V64c0-26.5-21.5-48-48-48H256zM192 64c0-35.3 28.7-64 64-64H448c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H256c-35.3 0-64-28.7-64-64V64zM56 128H160v16H56c-22.1 0-40 17.9-40 40V456c0 22.1 17.9 40 40 40H172.8c3.3 5.7 7.2 11.1 11.6 16H56c-30.9 0-56-25.1-56-56V184c0-30.9 25.1-56 56-56zm8 216c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H88c-13.3 0-24-10.7-24-24V344zm24-8c-4.4 0-8 3.6-8 8v32c0 4.4 3.6 8 8 8h32c4.4 0 8-3.6 8-8V344c0-4.4-3.6-8-8-8H88zm0-144h32c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H88c-13.3 0-24-10.7-24-24V216c0-13.3 10.7-24 24-24zm-8 24v32c0 4.4 3.6 8 8 8h32c4.4 0 8-3.6 8-8V216c0-4.4-3.6-8-8-8H88c-4.4 0-8 3.6-8 8zM368 376V344c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H392c-13.3 0-24-10.7-24-24zm24 8h32c4.4 0 8-3.6 8-8V344c0-4.4-3.6-8-8-8H392c-4.4 0-8 3.6-8 8v32c0 4.4 3.6 8 8 8zm0-112c-13.3 0-24-10.7-24-24V216c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H392zm-8-24c0 4.4 3.6 8 8 8h32c4.4 0 8-3.6 8-8V216c0-4.4-3.6-8-8-8H392c-4.4 0-8 3.6-8 8v32zM256 376V344c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H280c-13.3 0-24-10.7-24-24zm24 8h32c4.4 0 8-3.6 8-8V344c0-4.4-3.6-8-8-8H280c-4.4 0-8 3.6-8 8v32c0 4.4 3.6 8 8 8zm0-312h32c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H280c-13.3 0-24-10.7-24-24V96c0-13.3 10.7-24 24-24zm-8 24v32c0 4.4 3.6 8 8 8h32c4.4 0 8-3.6 8-8V96c0-4.4-3.6-8-8-8H280c-4.4 0-8 3.6-8 8zM256 248V216c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H280c-13.3 0-24-10.7-24-24zm24 8h32c4.4 0 8-3.6 8-8V216c0-4.4-3.6-8-8-8H280c-4.4 0-8 3.6-8 8v32c0 4.4 3.6 8 8 8zM392 72h32c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H392c-13.3 0-24-10.7-24-24V96c0-13.3 10.7-24 24-24zm-8 24v32c0 4.4 3.6 8 8 8h32c4.4 0 8-3.6 8-8V96c0-4.4-3.6-8-8-8H392c-4.4 0-8 3.6-8 8z"]
  };
  var faChevronsRight = {
    prefix: 'fat',
    iconName: 'chevrons-right',
    icon: [512, 512, ["chevron-double-right"], "f324", "M53.7 477.7l216-216c3.1-3.1 3.1-8.2 0-11.3l-216-216c-3.1-3.1-8.2-3.1-11.3 0s-3.1 8.2 0 11.3L252.7 256 42.3 466.3c-3.1 3.1-3.1 8.2 0 11.3s8.2 3.1 11.3 0zm192 0l216-216c3.1-3.1 3.1-8.2 0-11.3l-216-216c-3.1-3.1-8.2-3.1-11.3 0s-3.1 8.2 0 11.3L444.7 256 234.3 466.3c-3.1 3.1-3.1 8.2 0 11.3s8.2 3.1 11.3 0z"]
  };
  var faTriangleExclamation = {
    prefix: 'fat',
    iconName: 'triangle-exclamation',
    icon: [512, 512, [9888, "exclamation-triangle", "warning"], "f071", "M20.7 412.3c-3.1 5.3-4.7 11.2-4.7 17.3c0 19 15.4 34.4 34.4 34.4H461.6c19 0 34.4-15.4 34.4-34.4c0-6.1-1.6-12.1-4.7-17.3L290.3 67.7C283.2 55.5 270.1 48 256 48s-27.2 7.5-34.3 19.7L20.7 412.3zM6.9 404.2l201-344.6C217.9 42.5 236.2 32 256 32s38.1 10.5 48.1 27.6l201 344.6c4.5 7.7 6.9 16.5 6.9 25.4c0 27.8-22.6 50.4-50.4 50.4H50.4C22.6 480 0 457.4 0 429.6c0-8.9 2.4-17.7 6.9-25.4zM256 160c4.4 0 8 3.6 8 8V328c0 4.4-3.6 8-8 8s-8-3.6-8-8V168c0-4.4 3.6-8 8-8zM240 384a16 16 0 1 1 32 0 16 16 0 1 1 -32 0z"]
  };
  var faExclamationTriangle = faTriangleExclamation;

  exports.faArrowRightFromBracket = faArrowRightFromBracket;
  exports.faBadgePercent = faBadgePercent;
  exports.faBan = faBan;
  exports.faBug = faBug;
  exports.faBuilding = faBuilding;
  exports.faBuildings = faBuildings;
  exports.faCheck = faCheck;
  exports.faChevronDown = faChevronDown;
  exports.faChevronRight = faChevronRight;
  exports.faChevronUp = faChevronUp;
  exports.faChevronsRight = faChevronsRight;
  exports.faCircle = faCircle;
  exports.faCircleDollar = faCircleDollar;
  exports.faClock = faClock;
  exports.faCopy = faCopy;
  exports.faDoNotEnter = faDoNotEnter;
  exports.faExclamationTriangle = faExclamationTriangle;
  exports.faFlag = faFlag;
  exports.faFlagCheckered = faFlagCheckered;
  exports.faGear = faGear;
  exports.faGlobe = faGlobe;
  exports.faHammer = faHammer;
  exports.faHouse = faHouse;
  exports.faIndustry = faIndustry;
  exports.faInfo = faInfo;
  exports.faInfoCircle = faInfoCircle;
  exports.faLoader = faLoader;
  exports.faLocationDot = faLocationDot;
  exports.faNotebook = faNotebook;
  exports.faPenCircle = faPenCircle;
  exports.faPhone = faPhone;
  exports.faPlus = faPlus;
  exports.faQuestionCircle = faQuestionCircle;
  exports.faRotateRight = faRotateRight;
  exports.faSpinnerThird = faSpinnerThird;
  exports.faStopwatch = faStopwatch;
  exports.faTrafficLight = faTrafficLight;
  exports.faTrash = faTrash;
  exports.faUser = faUser;
  exports.faXmark = faXmark;

  Object.defineProperty(exports, '__esModule', { value: true });

}));
